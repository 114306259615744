import { createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3f442f7c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["src"]
const _hoisted_2 = {
  key: 1,
  class: "user-initial"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["user-avatar", {default: !$setup.avatar}]),
    style: _normalizeStyle($setup.userAvatarStyle)
  }, [
    _createVNode($setup["Loader"], {
      loading: $setup.loading,
      size: $props.size
    }, null, 8, ["loading", "size"]),
    ($setup.avatar)
      ? (_openBlock(), _createBlock($setup["NIcon"], {
          key: 0,
          size: $props.size
        }, {
          default: _withCtx(() => [
            _createElementVNode("img", {
              src: $setup.avatar,
              alt: "avatar"
            }, null, 8, _hoisted_1)
          ]),
          _: 1
        }, 8, ["size"]))
      : ($props.name)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString($props.name[0].toUpperCase()), 1))
        : (_openBlock(), _createBlock($setup["NIcon"], {
            key: 2,
            size: $props.isHeader ? 16 : $props.size / 2
          }, {
            default: _withCtx(() => [
              _createVNode($setup["DefaultAvatar"], { color: "currentColor" })
            ]),
            _: 1
          }, 8, ["size"]))
  ], 6))
}
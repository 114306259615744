import { AxiosResponse } from "axios";
import { avatarCacheMaxAge } from "@/constants/avatarCacheMaxAge";
import ApiService from "@/services";
import CacheService from "@/services/CacheService";

export default class FileService {
  static getLastUpdateDates(): Promise<AxiosResponse> {
    return ApiService.get("/v1/files/last");
  }
  static uploadFile(payload: {
    formData: FormData;
    onUploadProgressEvent: (progressEvent: ProgressEvent) => void;
  }): Promise<AxiosResponse> {
    return ApiService.post("/upload", payload.formData, {
      onUploadProgress: payload.onUploadProgressEvent,
      baseURL: process.env.VUE_APP_UPLOAD_API_URL,
    });
  }
  static uploadDocumentsFile(payload: {
    formData: FormData;
  }): Promise<AxiosResponse> {
    return ApiService.post("/document/upload", payload.formData, {
      baseURL: process.env.VUE_APP_UPLOAD_API_URL,
    });
  }
  static getConvertedModel(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/files/${id}`);
  }
  static uploadCustomerOrders(payload: FormData): Promise<AxiosResponse> {
    return ApiService.post("/v1/orders/import", payload);
  }
  static uploadPurchaseOrders(payload: FormData): Promise<AxiosResponse> {
    return ApiService.post("/v1/mfr-orders/import", payload);
  }
  static uploadParts(payload: FormData): Promise<AxiosResponse> {
    return ApiService.post("/v1/parts/import", payload);
  }
  static uploadPartProductionProcess(
    payload: FormData
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/production-processes/import", payload);
  }
  static uploadPOTemplate(
    formData: FormData,
    type: string
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/files", formData, { params: { type } });
  }
  static downloadPurchaseOrder(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/files/${id}`);
  }
  static uploadFileWithType(
    formData: FormData,
    params: {
      type: string;
      customerId?: string;
      mfrId?: string;
    }
  ): Promise<AxiosResponse> {
    return ApiService.post("/v1/files", formData, { params });
  }
  static getFile(id: string, isAvatar = false): Promise<AxiosResponse> {
    return CacheService.get(`/v1/files/${id}`, {
      ...(isAvatar && { cache: { maxAge: avatarCacheMaxAge } }),
    });
  }
  static downloadFile(id: string): Promise<AxiosResponse> {
    return ApiService.get(`/v1/files/${id}`);
  }

  static downloadPartCostingReport(): Promise<AxiosResponse> {
    return ApiService.get("/v2/admin-reports/part-costing-report");
  }

  static downloadPartCostingReportById(rfqId: string): Promise<AxiosResponse> {
    return ApiService.get(`/v2/admin-reports/part-costing-report/${rfqId}`, {
      responseType: "blob",
    });
  }

  static downloadPartProductionProcessReport(): Promise<AxiosResponse> {
    return ApiService.get("/v2/admin-reports/production-process-report", {
      responseType: "blob",
    });
  }

  static downloadPartsReport(): Promise<AxiosResponse> {
    return ApiService.get("/v2/admin-reports/part-report", {
      responseType: "blob",
    });
  }

  static downloadPartMetadataReport(): Promise<AxiosResponse> {
    return ApiService.get("/v2/admin-reports/part-metadata-report", {
      responseType: "blob",
    });
  }
}

import { createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c4ddac50"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "checkout-purchase-order-steps"
}
const _hoisted_2 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode($setup["Loader"], { loading: $setup.loading }, null, 8, ["loading"]),
    (!$setup.loading)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["checkout-purchase-order", {sent: $setup.currentStep === 4}])
        }, [
          ($setup.currentStep !== 4)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                _createElementVNode("div", {
                  class: _normalizeClass(["checkout-purchase-order-title", {'extra-space': $setup.gotCompanyAccount}])
                }, "Checkout with PO", 2),
                (!$setup.gotCompanyAccount)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.steps, (step, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                          class: "checkout-purchase-order-steps-step",
                          key: step.id
                        }, [
                          _createElementVNode("div", {
                            class: _normalizeClass(["checkout-purchase-order-steps-step-number", { active: step.number === $setup.currentStep }])
                          }, _toDisplayString(index + 1), 3),
                          _createElementVNode("div", {
                            class: "checkout-purchase-order-steps-step-title",
                            innerHTML: step.title
                          }, null, 8, _hoisted_2)
                        ]))
                      }), 128))
                    ]))
                  : _createCommentVNode("", true)
              ], 64))
            : _createCommentVNode("", true),
          ($setup.currentStep === 1)
            ? (_openBlock(), _createBlock($setup["CompanyInfo"], {
                key: 1,
                rfqId: $setup.rfqId,
                isDeleted: $setup.isDeleted,
                onNextStep: _cache[0] || (_cache[0] = ($event: any) => ($setup.nextStep(2)))
              }, null, 8, ["rfqId", "isDeleted"]))
            : ($setup.currentStep === 2)
              ? (_openBlock(), _createBlock($setup["CompanyAccount"], {
                  key: 2,
                  onNextStep: _cache[1] || (_cache[1] = ($event: any) => ($setup.nextStep(3))),
                  customerId: $setup.customerId,
                  shippingAddress: $setup.shippingAddress
                }, null, 8, ["customerId", "shippingAddress"]))
              : ($setup.currentStep === 3)
                ? (_openBlock(), _createBlock($setup["UploadPOFile"], {
                    key: 3,
                    rfqId: $setup.rfqId,
                    costingVersion: $setup.costingVersion,
                    onNextStep: _cache[2] || (_cache[2] = ($event: any) => ( $setup.nextStep(4)))
                  }, null, 8, ["rfqId", "costingVersion"]))
                : ($setup.currentStep === 4)
                  ? (_openBlock(), _createBlock($setup["PurchaseOrderSubmitted"], {
                      key: 4,
                      orderNumber: $setup.rfqNumber
                    }, null, 8, ["orderNumber"]))
                  : _createCommentVNode("", true)
        ], 2))
      : _createCommentVNode("", true)
  ], 64))
}